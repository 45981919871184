var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vx-card", [
    _c("div", { staticClass: "grid grid-cols-12 gap-4" }, [
      _vm.showEmptyGraphWarning
        ? _c(
            "div",
            { staticClass: "col-span-12" },
            [
              _c(
                "vs-alert",
                {
                  staticClass: "mt-5 text-warning mb-4",
                  attrs: {
                    title: _vm.$t("alerta"),
                    color: "rgb(231, 154, 23)",
                    active: "true",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("nao-existem-dados-para-esta-consulta")) +
                      " "
                  ),
                ]
              ),
              _vm.someQuestionnaireNoResume
                ? _c(
                    "vs-alert",
                    {
                      staticClass: "mt-5 text-warning mb-4",
                      attrs: {
                        title: _vm.$t("alerta"),
                        color: "rgb(231, 154, 23)",
                        active: "true",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("nao-e-possivel-calcular-o-desempenho-par")
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "col-span-12" }, [
        _c("fieldset", { staticClass: "p-2 pt-0 border border-grey" }, [
          _c("legend", { staticClass: "px-1 font-bol" }, [
            _vm._v(" " + _vm._s(_vm.$t("filtros")) + " "),
          ]),
          _c(
            "div",
            { staticClass: "grid items-end p-2 gap-4" },
            [
              _c("tag-suggestion", {
                ref: "select_questionnaire",
                staticClass: "row-start-1",
                attrs: {
                  max: 20,
                  column: "name,description",
                  model: "ContentQuestionnaire",
                  label: _vm.$t("questionarios"),
                  with: ["answers.user"],
                  modelToTagMapping: (model) => ({
                    text: model.name,
                    data: model,
                  }),
                  placeholderText: "Digite o nome do questionário",
                },
                model: {
                  value: _vm.questionnaires,
                  callback: function ($$v) {
                    _vm.questionnaires = $$v
                  },
                  expression: "questionnaires",
                },
              }),
              _c(
                "div",
                { staticClass: "row-start-2 flex items-end gap-2" },
                [
                  _c("user-repository", {
                    staticClass: "flex-grow",
                    attrs: { userRepository: _vm.userRepository },
                    model: {
                      value: _vm.selectedUsers,
                      callback: function ($$v) {
                        _vm.selectedUsers = $$v
                      },
                      expression: "selectedUsers",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-row flex-wrap place-self-center gap-1",
                    },
                    [
                      _c(
                        "vs-label",
                        { staticClass: "flex-grow w-full text-sm pb-2" },
                        [_vm._v(_vm._s(_vm.$t("calculo-porcentagem")))]
                      ),
                      _c(
                        "vs-label",
                        { class: _vm.averageKind ? "" : "text-primary" },
                        [_vm._v(_vm._s(_vm.$t("pontuacao-media")))]
                      ),
                      _c("vs-switch", {
                        staticClass: "bg-primary",
                        on: { input: _vm.updateGrid },
                        model: {
                          value: _vm.averageKind,
                          callback: function ($$v) {
                            _vm.averageKind = $$v
                          },
                          expression: "averageKind",
                        },
                      }),
                      _c(
                        "vs-label",
                        { class: _vm.averageKind ? "text-primary" : "" },
                        [_vm._v(_vm._s(_vm.$t("quantidade-de-acertos")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "py-auto px-4 mr-2",
                          attrs: { disabled: !_vm.validFilters },
                          on: { click: _vm.updateData },
                        },
                        [_vm._v(_vm._s(_vm.$t("filtrar")))]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "py-auto px-4",
                          attrs: { type: "border" },
                          on: { click: _vm.clearFilters },
                        },
                        [_vm._v(_vm._s(_vm.$t("action.clear")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm.echartData &&
      _vm.echartData.series &&
      _vm.echartData.series.length > 0
        ? _c(
            "div",
            { staticClass: "col-span-12" },
            [
              _vm.echartData
                ? _c("e-charts", {
                    ref: "graph",
                    attrs: {
                      autoresize: "",
                      options: _vm.echartData,
                      "auto-resize": "",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.questionPerformanceReport &&
      _vm.questionPerformanceReport.questions.length > 0 &&
      !this.graphIsEmpty
        ? _c(
            "div",
            { staticClass: "col-span-12 flex flex-col gap-4" },
            [
              _c("vs-table", {
                attrs: { data: _vm.questionPerformanceReport.questions },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _vm._l(data, function (question, position) {
                          return _c(
                            "vs-tr",
                            { key: question.questionId },
                            [
                              _c("vs-td", [
                                _c(
                                  "div",
                                  {
                                    class:
                                      _vm.getQuestionReportRowClass(question),
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-row gap-2 w-full bg-faint-grey p-3 question-title",
                                      },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("questao-position-1", [
                                                position + 1,
                                              ])
                                            ) + " "
                                          ),
                                        ]),
                                        _c("div", {
                                          directives: [
                                            {
                                              name: "html-safe",
                                              rawName: "v-html-safe",
                                              value: _vm.shortTitle(
                                                question.questionData.title
                                              ),
                                              expression:
                                                "shortTitle(question.questionData.title)",
                                            },
                                          ],
                                          staticClass:
                                            "editor-content ck-content",
                                        }),
                                      ]
                                    ),
                                    _vm._l(
                                      question.questionnaires,
                                      function (questionnaire, position) {
                                        return _c(
                                          "div",
                                          {
                                            key: position,
                                            staticClass:
                                              "flex w-full gap-2 px-3 pt-1",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip.auto",
                                                    value: {
                                                      content:
                                                        questionnaire.name,
                                                      delay: {
                                                        show: 100,
                                                        hide: 100,
                                                      },
                                                    },
                                                    expression:
                                                      "{\n                      content: questionnaire.name,\n                      delay: { show: 100, hide: 100 }\n                    }",
                                                    modifiers: { auto: true },
                                                  },
                                                ],
                                                staticClass:
                                                  "font-semibold truncate w-96",
                                                style: `color: ${_vm.echartData.color[position]}`,
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(questionnaire.name) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatFloat(
                                                    questionnaire.value
                                                  )
                                                ) + "%"
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ],
                  null,
                  false,
                  2694233850
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }